export enum LeadSource {
  HEALTH_HELP_CALLBACK = 'fr-ii-ck-comparateurmutuelleaide',
  HEALTH_BANNER_CALLBACK = 'fr-ii-ck-comparateurmutuelle',
  HEALTH_BANNER_CALLBACK_NO_OFFERS = 'fr-ii-ck-comparateurmutuellenooffer'
}

export enum Owner {
  INSURANCE_FRANCE_MANAGER = '1499247000795478875',
}

export enum Name {
  CALLBACK = 'Callback Request FR Insurance Comparator',
}

export interface Interaction {
  lead_source: LeadSource,
  name: Name,
  owner: Owner,
  email: string,
  origin_url: string,
  phone: string,
  callback: string,
}

export interface Contact {
  firstName: string,
  lastName: string,
  owner: Owner,
  lead_source: LeadSource,
  birthdate: string,
  address: string,
}

export interface Record {
  interaction?: Interaction,
  contact?: Contact,
}
