import {
  FC,
} from 'react'

import {
  ReactComponent as BlockCircle,
} from '@assets/icons/block-circle.svg'
import {
  ReactComponent as Database,
} from '@assets/icons/database.svg'

interface AdministrationFeesIconProps {
  fee: number
}

const AdministrationFeesIcon: FC<AdministrationFeesIconProps> = ({
  fee,
}) => {
  const Icon = fee > 0 ? Database : BlockCircle

  return (
    <Icon className='inline-block fill-success-300' height={16}
      width={16}
    />
  )
}

export default AdministrationFeesIcon
