import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  ReactComponent as CheckCircle,
} from '@assets/icons/check-circle.svg'
import {
  Insurer,
} from '@root/domain/Insurer'
import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  useFetchUserQuery,
} from '@root/services/user'

interface CallbackCompleteProps {
  insurer: Insurer,
  merType: string,
}
const CallbackComplete:FC<CallbackCompleteProps> = ({
  insurer,
  merType,
}) => {
  const {
    t,
  } = useTranslation(['modal'])

  const {
    estimationId,
  } = useParams<{estimationId: string}>()

  const getEstimation = useGetOffersByEstimationIdQuery({
    estimationId: estimationId as string,
  })
  const fetchUser = useFetchUserQuery(getEstimation.data?.estimation?.userId ?? '')

  const message = merType === 'IMMEDIATE_CALLBACK' ? t('modal:modal.callbackDone.description.immediate') : t('modal:modal.callbackDone.description.delayed')

  return (
    <div className='flex flex-col items-center text-wrap p-4 text-center md:p-2'>
      <CheckCircle />
      <div>
        <p className='mb-4 mt-6 justify-normal text-lg font-semibold text-success-400 md:text-2xl'>{t('modal:modal.callbackDone.title', {
          insurer: insurer.name,
        })}!</p>
      </div>
      <p className='mb-4 text-neutral-400 md:text-lg'>{message}
        <span className='font-semibold'>{fetchUser?.data?.phoneNumberNational}</span></p>
    </div>
  )
}

export default CallbackComplete
