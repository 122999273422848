import {
  DATE_MASK, InputGroup, getErrorMessages, ErrorMessages, MaskedInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const ChildrenBirthDate: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    form: {
      handleSubmit,
      register,
      formState: {
        errors,
      },
      watch,
    },
  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const params = useParams()

  const id = parseInt(params.id as string, 10)

  const errorType = errors.adults?.[id]?.birthDate?.type

  const status = useGetInputStatus(errorType)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addChildrenBirthDate'),
  }, errorType)

  const children = parseInt(watch('amountOfChildren'))

  const nextHandler = () => {
    const nextChildren = id + 1
    if (children > nextChildren) {
      return navigate(`${wizardRoutes.CHILDREN}/${nextChildren}${wizardRoutes.CHILDREN_BIRTH_DATE}`)
    }

    navigate(wizardRoutes.POSTAL_CODE)
  }

  const backHandler = () => {
    const previousChildren = id - 1
    if (previousChildren >= 0) {
      return navigate(`${wizardRoutes.CHILDREN}/${previousChildren}${wizardRoutes.CHILDREN_BIRTH_DATE}`)
    }

    navigate(wizardRoutes.CHILDREN)
  }

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup description={t('wizard:wizard.steps.birthDate.description')}
          errors={inputErrors}
        >
          <MaskedInput label={t('wizard:wizard.steps.birthDate.label')}
            mask={DATE_MASK}
            status={status}
            {...register(`children.${id}.birthDate` as 'children.0.birthDate', {
              required: true,
            })}
            dataCy={`children-${id}`}
          />

        </InputGroup>
      </div>

      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default ChildrenBirthDate
