import {
  FC, useEffect,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  Insurer,
} from '@root/domain/Insurer'

interface SubscriptionCompleteProps {
  insurer: Insurer,
  url: string
}

const SubscriptionComplete:FC<SubscriptionCompleteProps> = ({
  insurer,
  url,
}) => {
  const {
    t,
  } = useTranslation(['modal'])

  const {
    name,
  } = insurer

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.open(url, '_blank')
    }, 2000)

    // Cleanup function to clear the timeout if the component unmounts before the timeout
    return () => clearTimeout(timeoutId)
  }, [url])

  return (
    <div className='mx-auto text-center text-neutral-400 md:p-2 md:text-lg'>
      { url && <>
        {t('modal:modal.subscriptionOnline.description')}
        <span className='font-bold'>{name}...</span>
      </>}
    </div>)
}

export default SubscriptionComplete
