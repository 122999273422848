import {
  FC, SVGProps,
} from 'react'

interface PresentationInteractionMessageContentProps {
  message: string,
  Icon?: FC<SVGProps<SVGSVGElement>>,
}

const PresentationInteractionMessageContent: FC<PresentationInteractionMessageContentProps> = ({
  message,
  Icon,
}) => {
  return (
    <div className='flex gap-[6px]'>
      {Icon && <Icon className="fill-green-500"/>}
      <span dangerouslySetInnerHTML={{
        __html: message,
      }}
      />
    </div>
  )
}

export default PresentationInteractionMessageContent
