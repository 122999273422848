import {
  FC,
} from 'react'
import {
  Outlet,
} from 'react-router-dom'
import {
  Default,
} from '@selectra-it/selectra-ui'

import Header from '@components/ui/Header'
// import Debugger from '@components/ui/Debugger'

const App: FC = () => {
  return (
    <div className="overflow-x-hidden">
      <Default header={<Header />}>
        <Outlet />

        {/* <Debugger /> */}
      </Default>

    </div>
  )
}

export default App
