import {
  FC,
} from 'react'
import {
  InputGroup, TextInput, getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'

const Name: FC = () => {
  const {
    form: {
      register,
      formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({ })

  const firstNameInputStatus = useGetInputStatus(errors.firstName?.type)
  const lastNameInputStatus = useGetInputStatus(errors.lastName?.type)

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addFirstName'),
  }, errors.firstName?.type)

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup errors={inputErrors}>
          <TextInput
            label={t('wizard:wizard.steps.name.firstName.label')}
            {...register('firstName', {
              required: true,
            })}
            status={firstNameInputStatus}
            dataCy="first-name"
          />
        </InputGroup>

        <InputGroup errors={getErrorMessages({
          ...ErrorMessages,
        }, errors.lastName?.type)}
        >
          <TextInput
            label={t('wizard:wizard.steps.name.lastName.label')}
            {...register('lastName', {
              required: true,
            })}
            status={lastNameInputStatus}
            dataCy="last-name"
          />
        </InputGroup>
      </div>

      <NavigationButtons onBackHandler={onBackHandler}/>

    </form>
  )
}

export default Name
