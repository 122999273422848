import {
  apiService,
} from '@services/apiService'

export interface Profession {
  key: string,
  label: string,
  id: string,
}

interface ProfessionResponse {
  items: Profession[];
}

export const ProfessionApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getProfession: builder.query<Profession[], void>({
      query: () => 'api/health/professions',
      transformResponse: (response: ProfessionResponse) => response.items,
    }),
  }),
})

export const {
  useGetProfessionQuery,
} = ProfessionApi
