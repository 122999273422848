import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'

import CallbackForm from './CallbackForm'

const CallbackCreate: FC = () => {
  const {
    estimationId, offerId,
  } = useParams()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!offer || !insurer) {
    return null
  }

  return (
    <CallbackForm offer={offer} insurer={insurer} />
  )
}

export default CallbackCreate
