import {
  ReactNode,
} from 'react'

import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import {
  Beneficiary,
} from '@root/domain/Beneficiary'
import PresentationInteractionMessageContent from '@components/ui/wizard/InteractionMessage/PresentationInteractionMessageContent'
import {
  ReactComponent as Check,
} from '@assets/icons/check.svg'

const messages: Record<wizardRoutes, (state: WizardFormData, pathname: string) => Array<string | ReactNode>> = {
  [wizardRoutes.PRESENTATION]: () => [
    'Bienvenue sur le comparateur de mutuelles Selectra !',
    <PresentationInteractionMessageContent Icon={Check} key='presentation_1'
      message={'Vos <strong>  devis en 3 min  </strong> top chrono'}
    />,
    <PresentationInteractionMessageContent Icon={Check} key='presentation_2'
      message='<strong> Comparez </strong> les offres en fonction des prix et garanties'
    />,
    <PresentationInteractionMessageContent Icon={Check} key='presentation_3'
      message='<strong> Pas de démarchage </strong> non voulu'
    />
  ],
  [wizardRoutes.BENEFICIARY]: () => [
    'Qui souhaitez-vous assurer ?'
  ],
  [wizardRoutes.ADULT_INFORMATION]: () =>
    [],
  [wizardRoutes.INSURANCE_GENDER]: (state, pathname) => {
    const {
      beneficiary,
    } = state
    const isSingle = beneficiary === Beneficiary.PRINCIPAL_AND_CHILDREN || beneficiary === Beneficiary.PRINCIPAL
    const id = pathname.split('/')[2]
    const coupleMessage = id === '1' ? 'Passons à votre conjoint(e), il/elle est :' : 'Commençons par votre profil, vous êtes :'

    const message = isSingle ? 'Vous êtes :' : coupleMessage

    return [message]
  },
  [wizardRoutes.BIRTH_DATE]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa date de naissance est le :' : 'Votre date de naissance est le :'

    return [message]
  },
  [wizardRoutes.PROFESSION]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Sa profession ou activité est :' : 'Votre profession ou activité est :'

    return [message]
  },
  [wizardRoutes.SOCIAL_REGIME]: (state, pathname) => {
    const id = pathname.split('/')[2] ?? 0
    const message = id === '1' ? 'Son régime social est :' : 'Votre régime social est :'

    return [message]
  },
  [wizardRoutes.CHILDREN]: () => [
    "Combien d'enfants avez-vous ?"
  ],
  [wizardRoutes.CHILDREN_BIRTH_DATE]: (state, pathname) => {
    interface Childs {
      [key: number]: string;
    }
    const childs:Childs = {
      0: 'premier',
      1: 'deuxième',
      2: 'troisième',
      3: 'quatrième',
      4: 'cinquième',
      5: 'sixième',
      6: 'septième',
      7: 'huitième',
      8: 'neuvième',
      9: 'dixième',
    }
    const hasMoreThanOneChild = parseInt(state.amountOfChildren) > 1
    const id = parseInt(pathname.split('/')[2]) ?? 0

    const message = !hasMoreThanOneChild ? 'La date de naissance de votre enfant est le :' : `La date de naissance de votre ${childs[id]} enfant est le :`

    return [message]
  },
  [wizardRoutes.POSTAL_CODE]: () => ['Votre code postal est le :'
  ],
  [wizardRoutes.INSURANCE_DATE]: () => [
    'Quelle date de début de contrat souhaitez-vous sur vos devis ?'
  ],
  [wizardRoutes.REGULAR_HEALTH_LEVEL]: () => [
    'Pour des offres adaptées, choisissez vos <strong>niveaux de remboursement.</strong>',
    'Soins courants :',
    'Min : Si vous vous rendez très rarement chez le médecin et n’avez jamais besoin de radiographies ou médicaments.',
    'Faible : Si votre médecin généraliste pratique le tarif conventionnel, que vous n’avez pas un besoin fréquent de radiographies et que vous n’avez pas besoin de traitements non remboursés par la sécurité sociale.',
    'Moyen / Fort : Si vous allez voir des médecins et experts qui pratiquent des dépassement d’honoraires.'
  ],
  [wizardRoutes.HOSPITAL_LEVEL]: () => [
    'Hospitalisation :',
    'Min / Faible : Si vous êtes à l\'aise avec l\'idée d\'être peu remboursé en cas de dépassement d\'honoraire à l\'hôpital (intervention dans une clinique privée par exemple).',
    'Moyen / Fort : Si vous souhaitez une chambre particulière et/ou si vous souhaitez un bon niveau de remboursement à l\'hôpital, même en cas de dépassement d\'honoraires (clinique privée par exemple).'
  ],
  [wizardRoutes.OPTICAL_LEVEL]: () => [
    'Optique :',
    'Min : Si vous n’avez pas de lunettes.',
    'Faible : Si vous avez une correction très faible et des verres peu coûteux.',
    'Moyen / Fort : Si vos deux verres coûtent plus de 200€ et/ou si vous souhaitez une prise en charge de vos lentilles.'
  ],
  [wizardRoutes.DENTAL_LEVEL]: () => [
    'Dentaire :',
    'Min : Si votre dentition est bonne et que vous ne vous rendez jamais chez le dentiste.',
    'Faible : Si votre dentition est bonne et que vous vous contentez de faire des visites de routine chez le dentiste.',
    'Moyen / Fort : Si vous avez recours à l’orthodontie, aux inlays, aux onlays ou aux prothèses dentaires.'
  ],
  [wizardRoutes.NAME]: () => [
    'Votre prénom et nom sont :'
  ],
  [wizardRoutes.PHONE_NUMBER]: () => [
    'Quel est votre numéro de téléphone ?',
    'Nous non plus, nous n’aimons pas être démarchés par téléphone ! Votre numéro ne sera utilisé que si vous faite une demande de rappel. 📞'
  ],
  [wizardRoutes.EMAIL]: () => [
    'Dernière question, quelle est votre adresse email ? Pas d’inquiétude, Selectra ne vous enverra pas d’emails promotionnels !'
  ]
  ,
}

export default messages
