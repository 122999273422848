import {
  FC,
} from 'react'

import {
  Insurer,
} from '@root/domain/Insurer'

interface SirenOriasNumberProps {
  insurer: Insurer,
}

const SirenOriasNumber: FC<SirenOriasNumberProps> = ({
  insurer,
}) => {
  const label = insurer.oriasNumber ? 'ORIAS' : 'SIREN'
  const value = insurer.oriasNumber ? insurer.oriasNumber : insurer.sirenNumber

  return (
    <p className='text-[10px] leading-[160%] text-neutral-350 md:text-sm'>{label}: {value}</p>
  )
}

export default SirenOriasNumber
