import {
  ButtonGroupStatus,
} from '@selectra-it/selectra-ui'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

type useGetButtonGroupStatusProps = LiteralUnion<keyof RegisterOptions, string> | undefined

const useGetButtonGroupStatus = (error: useGetButtonGroupStatusProps) => {
  if (error) { return ButtonGroupStatus.DANGER }

  return ButtonGroupStatus.DEFAULT
}

export default useGetButtonGroupStatus
