import {
  FC,
} from 'react'

import {
  CustomerService,
} from '@root/domain/Insurer'
import {
  ReactComponent as Chat,
} from '@assets/icons/chat.svg'
import {
  ReactComponent as Phone,
} from '@assets/icons/smartphone.svg'

const IconMap = {
  [CustomerService.online_chat]: Chat,
  [CustomerService.phone]: Phone,
}

interface CustomerServiceIconProps {
  type: CustomerService
}

const CustomerServiceIcon: FC<CustomerServiceIconProps> = ({
  type,
}) => {
  const Icon = IconMap[type]

  return (
    <Icon className='inline-block fill-success-300' height={16}
      width={16}
    />
  )
}

export default CustomerServiceIcon
