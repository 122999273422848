import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  MerStatus,
} from '@root/services/offers'

import useMerQueryPolling from './useMerQueryPolling'
import Loading from './Status/Loading'
import CallbackComplete from './Status/CallbackComplete'

const CallbackStatus: FC = () => {
  const {
    estimationId,
    offerId,
    merId,
  } = useParams<{estimationId: string, offerId: string, merId: string}>()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  const {
    data,
    isSuccess,
  } = useMerQueryPolling({
    estimationId: estimationId as string,
    offerId: offerId as string,
    merId: merId as string,
  })

  if (!offer || !insurer) {
    return null
  }

  return (
    <>
      {isSuccess && data?.status === MerStatus.DONE
        ? (
          <CallbackComplete insurer={insurer} merType={data.type} />
        )
        : (
          <Loading />
        )}
    </>
  )
}

export default CallbackStatus
