import {
  Mode,
  useForm,
} from 'react-hook-form'
import {
  useCallback,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  useNavigate,
} from 'react-router-dom'

import {
  NavigateNext,
  WizardFormData,
  getRouteInformation,
} from '@root/domain/Wizard'
import {
  RootState, useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState, setFormField,
} from '@root/services/formSlice'

interface useEstimationWizardFormProps {
  mode?: Mode,
  revalidateMode?: Exclude<Mode, 'onTouched' | 'all'>
}

const useEstimationWizardForm = ({
  mode,
  revalidateMode,
}: useEstimationWizardFormProps) => {
  const defaultValues = useAppSelector((state: RootState) => getFormState(state))
  const dispatch = useDispatch()
  const form = useForm<WizardFormData>({
    mode: mode ?? 'onBlur',
    reValidateMode: revalidateMode ?? 'onBlur',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: {
      ...defaultValues,
    },
  })
  const navigate = useNavigate()
  const state = useAppSelector(state => getFormState(state))

  const next: NavigateNext = useCallback((data, options = {}, route = location.pathname) => {
    const {
      next,
    } = getRouteInformation(route, data)

    next?.path && navigate(next?.path, options)
  }, [navigate])

  const {
    handleSubmit,
  } = form

  const onSubmitHandler = useCallback(
    () => handleSubmit(async data => {
      await dispatch(setFormField(data))
      next?.(data, {})
    }),
    [handleSubmit, dispatch, next]
  )

  const onBackHandler = useCallback(() => {
    const {
      previous,
    } = getRouteInformation(location.pathname, state)

    previous && navigate(previous?.path)
  }, [navigate, state])

  return {
    form, onSubmitHandler, onBackHandler, next,
  }
}

export default useEstimationWizardForm
