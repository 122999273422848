import {
  Stepper as BaseStepper,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  useLocation,
} from 'react-router-dom'

import {
  findCurrentStep,
} from '@root/domain/Wizard'
import {
  StepperGroup,
} from '@root/routes/wizad'

const StepperGroupOrder: Array<StepperGroup> = [StepperGroup.YOUR_SITUATION, StepperGroup.OTHER_BENEFICIARY, StepperGroup.YOUR_INFORMATION]

const StepperSteps = [
  {
    step: 1,
    name: 'Votre situation',
    status: 2,
  },
  {
    step: 2,
    name: 'Autre bénéficiaire',
    status: 0,
  },
  {
    step: 3,
    name: 'Vos informations',
    status: 1,
  }
]

const subRoutes: { [key: string]: number } = {
  '/information-beneficiaire': 0,
  '/nombre-enfants': 1,
}

const Stepper: FC = () => {
  const location = useLocation()

  const pathname = `/${location.pathname.split('/').at(1)}`

  const currentStep = findCurrentStep(pathname)
  let stepGroupIndex = StepperGroupOrder.findIndex(step => step === currentStep?.group)

  if (stepGroupIndex < 0) {
    stepGroupIndex = subRoutes[pathname]
  }

  const steps = StepperSteps.map((step, index) => {
    const status = stepGroupIndex > index
      ? 0
      : stepGroupIndex === index ? 1 : 2

    return {
      ...step,
      status,
    }
  })

  return (
    <BaseStepper steps={steps} />
  )
}

export default Stepper
