import {
  useTransition, animated,
} from '@react-spring/web'
import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useState,
} from 'react'
import {
  Route, Routes,
  useParams,
} from 'react-router-dom'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  LeadSource, Owner,
} from '@root/domain/crm/Record'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'

import {
  HELP_CALLBACK_REQUEST, HELP_INFORMATION,
} from './HelpModal/routes'
import HelpInformation from './HelpModal/HelpInformation'
import HelpRequestCallback from './HelpModal/HelpRequestCallback'

interface HelpModalProps {
  isHelpModalOpen: boolean;
  setIsHelpModalOpen: (status: boolean) => void;
}

const HelpModal: FC<HelpModalProps> = ({
  isHelpModalOpen,
  setIsHelpModalOpen,
}) => {
  const [location, setLocation] = useState<string>(HELP_INFORMATION)

  const transitions = useTransition(location, {
    from: {
      transform: 'translateX(10%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  })

  /**
   * Reset modal location when modal is closed
   */
  const onAfterClose = () => {
    setLocation(HELP_INFORMATION)
  }

  const {
    data,
  } = useGetUserFromEstimationIdRouteParam()
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data: estimationData,
  } = useGetOffersByEstimationId(estimationId)

  const mainRecipient = estimationData?.estimation.adults.find(adult => adult.type === 'PRINCIPAL')

  return (
    <Modal open={isHelpModalOpen} setOpen={setIsHelpModalOpen}
      onAfterClose={onAfterClose}
    >
      <div>
        {transitions((styles, location) => (
          <animated.div style={styles} className='flex flex-col gap-4  md:gap-6 md:p-2'>
            <Routes location={location}>
              <Route path={HELP_INFORMATION} element={<HelpInformation setLocation={setLocation} />} />
              <Route path={HELP_CALLBACK_REQUEST} element={
                <HelpRequestCallback user={data}
                  owner={Owner.INSURANCE_FRANCE_MANAGER} leadSource={LeadSource.HEALTH_HELP_CALLBACK}
                  address={estimationData?.estimation.address}
                  mainRecipient={mainRecipient}
                />}
              />
            </Routes>
          </animated.div>
        ))}
      </div>
    </Modal>
  )
}

export default HelpModal
