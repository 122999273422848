import {
  Button,
  ButtonSize,
  ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'

import {
  Offer,
} from '@root/domain/Offer'

interface SubscriptionButtonProps {
  offer: Offer
}

const SubscriptionButton: FC<SubscriptionButtonProps> = ({
  offer,
}) => {
  return (
    <Link to={`/offre/${offer.estimationId}/${offer.id}/mer`}>
      <Button
        type='submit'
        label="Suivant"
        variant={ButtonType.PRIMARY}
        size={ButtonSize.MEDIUM}
        pill
      />
    </Link>
  )
}

export default SubscriptionButton
