import {
  FC, MouseEventHandler, ReactElement, useEffect,
} from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import useEstimationWizardForm from '@hooks/useWizardForm'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

import GenderInformation from './GenderInformation'
import BirthDate from './BirthDate'
import ProfessionInformation from './ProfessionInformation'
import SocialRegimeInformation from './SocialRegimeInformation'

interface AdultInformationStep {
  name: string;
  path: string;
  element: ({
    nextHandler,
    backHandler,
  }: {
    nextHandler: () => void;
    backHandler: MouseEventHandler<HTMLButtonElement>;
  }) => ReactElement;
}

const routes: AdultInformationStep[] = [
  {
    name: 'gender',
    path: wizardRoutes.INSURANCE_GENDER,
    element: ({
      nextHandler, backHandler,
    }) => (
      <GenderInformation nextHandler={nextHandler} backHandler={backHandler} />
    ),
  },
  {
    name: 'birthDate',
    path: wizardRoutes.BIRTH_DATE,
    element: ({
      nextHandler, backHandler,
    }) => (
      <BirthDate nextHandler={nextHandler} backHandler={backHandler} />
    ),
  },
  {
    name: 'profession',
    path: wizardRoutes.PROFESSION,
    element: ({
      nextHandler, backHandler,
    }) => (
      <ProfessionInformation
        nextHandler={nextHandler}
        backHandler={backHandler}
      />
    ),
  },
  {
    name: 'socialRegime',
    path: wizardRoutes.SOCIAL_REGIME,
    element: ({
      nextHandler, backHandler,
    }) => (
      <SocialRegimeInformation
        nextHandler={nextHandler}
        backHandler={backHandler}
      />
    ),
  }
]

const AdultInformation: FC = () => {
  const navigate = useNavigate()
  const {
    pathname,
  } = useLocation()

  const {
    form: {
      watch,
    },
  } = useEstimationWizardForm({})

  const adultsCount = watch('adults').length
  const hasChildren = watch('children').length > 0
  const params = useParams()

  const id: number = parseInt(params.id as string, 10)

  const currentStepIndex = routes.findIndex(({
    path,
  }) =>
    pathname.includes(path)
  )

  const nextHandler = () => {
    const isLastSubRoute = routes.length === currentStepIndex + 1
    const isLastStep = adultsCount === id + 1 && isLastSubRoute

    if (isLastStep) {
      if (hasChildren) return navigate(wizardRoutes.CHILDREN)
      return navigate(wizardRoutes.POSTAL_CODE)
    }

    if (isLastSubRoute) {
      return navigate(
        `${wizardRoutes.ADULT_INFORMATION}/${id + 1}${routes.at(0)?.path}`
      )
    }

    const nextStep = routes[currentStepIndex + 1].path
    navigate(`${wizardRoutes.ADULT_INFORMATION}/${id}${nextStep}`)
  }

  const backHandler = () => {
    const isFirstSubRoute = currentStepIndex === 0
    const isFirstStep = id === 0 && isFirstSubRoute

    if (isFirstStep) return navigate(wizardRoutes.BENEFICIARY)
    if (isFirstSubRoute) {
      return navigate(
        `${wizardRoutes.ADULT_INFORMATION}/${id - 1}${routes.at(-1)?.path}`
      )
    }

    const previousStep = routes[currentStepIndex - 1].path
    navigate(`${wizardRoutes.ADULT_INFORMATION}/${id}${previousStep}`)
  }

  useEffect(() => {
    if (id > adultsCount && id < 0) {
      return navigate(wizardRoutes.BENEFICIARY)
    }
  }, [navigate, id, adultsCount])

  return (
    <Routes>
      {routes.map(route => (
        <Route
          key={route.path}
          element={
            <route.element
              nextHandler={nextHandler}
              backHandler={backHandler}
            />
          }
          path={route.path}
        />
      ))}
    </Routes>
  )
}

export default AdultInformation
