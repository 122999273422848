import {
  InputGroup, getErrorMessages, ErrorMessages, GoogleAutocomplete,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'
import {
  useNavigate,
} from 'react-router-dom'
import {
  Controller,
} from 'react-hook-form'

import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'

const PostalCode: FC = () => {
  const navigate = useNavigate()
  const {
    form: {
      watch,
      formState: {
        errors,
      },
      control,
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPostalCode'),
  }, errors?.address?.type)

  const children = parseInt(watch('amountOfChildren'), 10)

  const backHandler = () => {
    if (children) return navigate(`${wizardRoutes.CHILDREN}/${children - 1}${wizardRoutes.CHILDREN_BIRTH_DATE}`)
    onBackHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
    >
      <InputGroup
        description={t('wizard:wizard.steps.postalCode.description')}
        errors={inputErrors}
      >
        <Controller name='address' control={control}
          rules={{
            required: true,
          }} render={renderParams => {
            const {
              field: {
                value,
                ref,
                name,
                onChange,
              },
            } = renderParams

            return (
              <GoogleAutocomplete
                id='address'
                value={value}
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                componentRestrictions={{
                  country: 'FR',
                }}
                label={t('wizard:wizard.steps.postalCode.label')}
                types={[
                  'postal_code'
                ]}
                ref={ref}
                name={name}
                onChange={onChange}
              />
            )
          }}
        />

      </InputGroup>

      <NavigationButtons onBackHandler={backHandler}/>
    </form>
  )
}

export default PostalCode
