import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  ReactComponent as Check,
} from '@assets/icons/check.svg'
import {
  ReactComponent as Cross,
} from '@assets/icons/cross.svg'
import {
  Coverage,
} from '@root/domain/Offer'

import Step from './Step'

interface LevelProps {
  label: string
  level: Coverage
  isIncluded: boolean
}

const Level:FC<LevelProps> = ({
  label, level, isIncluded,
}) => {
  const hasLevel = true

  const {
    t,
  } = useTranslation('offers')

  return (
    <li className='flex items-center text-sm md:text-base'>
      <p className='flex min-w-[120px] gap-1 text-neutral-300 md:min-w-[170px]'>{t(`coverage_levels.${label}` as 'coverage_levels.consultation')}</p>
      { hasLevel
        ? <Step level={level} />
        : <p className='flex items-center gap-1 text-neutral-300'>  {isIncluded ? <><Check/> Incluse </> : <><Cross/> Non incluse </> }</p>
      }
    </li>
  )
}

export default Level
