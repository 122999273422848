import {
  FC,
} from 'react'
import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  Outlet,
  useLocation, useNavigate, useParams,
} from 'react-router-dom'

const SubscriptionModal: FC = () => {
  const {
    estimationId,
  } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const open = location.pathname.includes('mer')

  return (
    <Modal open={open} setOpen={() => {
      navigate(`/offre/${estimationId}`)
    }}
    >
      <Outlet />
    </Modal>
  )
}

export default SubscriptionModal
