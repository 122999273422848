import {
  Combobox,
} from '@selectra-it/selectra-ui'
import {
  forwardRef, useState,
} from 'react'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'

export interface CallbackDateTimeItem {
  label: string,
  id: string,
}

interface CallbackDateTimeProps {
  value: CallbackDateTimeItem | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void,
  name: string,
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
}

const options = [
  {
    label: 'Matin (9h - 12h)',
    id: '09:00',
  },
  {
    label: 'Midi (12h - 14h)',
    id: '12:00',
  },
  {
    label: 'Après-midi (14h - 18h)',
    id: '14:00',
  }
]

const CallbackDateTime = forwardRef<HTMLInputElement, CallbackDateTimeProps>(
  ({
    onChange, value, name, error,
  }, ref) => {
    const [query, setQuery] = useState<string>('')
    const status = useGetInputStatus(error)
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const afterTomorrow = new Date(tomorrow)
    afterTomorrow.setDate(tomorrow.getDate() + 1)

    const displayValue = (item: CallbackDateTimeItem | null): string => item?.label ?? ''

    const filteredItems =
      query === ''
        ? options
        : (options.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase())
        ))

    return (
      <Combobox<CallbackDateTimeItem>
        id='callback-time'
        ref={ref}
        displayValue={displayValue}
        item={value}
        setQuery={setQuery}
        setItem={onChange}
        items={filteredItems}
        label={'À quelle heure?'}
        status={status}
        name={name}
        canClearValue={false}
        immediate={true}
      />
    )
  })

CallbackDateTime.displayName = 'CallbackDateTime'

export default CallbackDateTime
