import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  ReactComponent as Check,
} from '@assets/icons/check.svg'
import {
  ReactComponent as Cross,
} from '@assets/icons/cross.svg'

interface AlternativeMedicineProps {
  alternativeMedicine: boolean;
}

const AlternativeMedicine: FC<AlternativeMedicineProps> = ({
  alternativeMedicine,
}) => {
  const {
    t,
  } = useTranslation(['offers', 'common'])

  return (
    <li className='flex items-center text-sm md:text-base'>
      <p className='flex min-w-[120px] gap-1 text-neutral-300 md:min-w-[170px]'>{t('offers:alternativeMedicine')}</p>
      <p className='flex items-center gap-1 text-neutral-300'>
        {alternativeMedicine ? <><Check/> {t('common:included')} </> : <><Cross/> {t('common:not_included')} </>}
      </p>
    </li>
  )
}

export default AlternativeMedicine
