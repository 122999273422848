import {
  FC,
} from 'react'
import {
  InputGroup, getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'
import PhoneNumberInput, {
} from '@components/form/PhoneNumberInput'
import {
  WizardFormData,
} from '@root/domain/Wizard'

const PhoneNumber: FC = () => {
  const {
    form: {
      register,
      formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({})

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPhoneNumber'),
    phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
  }, errors.phoneNumber?.number?.type)

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
    >
      <div className='flex flex-col gap-6'>
        <InputGroup errors={inputErrors}
          description={t('wizard:wizard.steps.phoneNumber.description')}
        >
          <PhoneNumberInput<WizardFormData>
            label={t('wizard:wizard.steps.phoneNumber.label')}
            register={register}
            name='phoneNumber.number'
            status={phoneNumberInputStatus}
          />
        </InputGroup>

      </div>

      <NavigationButtons onBackHandler={onBackHandler}/>

    </form>
  )
}

export default PhoneNumber
