import {
  BEST_PRICE, BEST_RATING, FREE_TRIAL_1_MONTH,
} from '@root/services/offers'

export default {
  offers: {
    message1: ', voici la <strong> liste des offres </strong> les plus adaptées à votre profil 👍',
    message2: 'Cette liste est non exhaustive et classée par prix croissant.',
  },
  noOffers: {
    message1: ', je ne suis pas parvenu à trouver une mutuelle qui corresponde à votre profil.',
    message2: 'Contactez gratuitement un de nos courtiers en assurance au ',
    message3: ' afin de trouver une offre adaptée 🤓',
  },
  coverage: {
    MIN: 'Minimum',
    LOW: 'Basique',
    MEDIUM: 'Moyenne',
    HIGH: 'Complète',
  },
  document: {
    IPID: 'Fiche produit simplifiée (IPID)',
    INFORMATION: 'Grille des garanties',
    GENERAL_CONDITIONS: 'Conditions générales',
  },
  tags: {
    [BEST_PRICE]: 'Le moins cher',
    [BEST_RATING]: 'Meilleure couverture',
    [FREE_TRIAL_1_MONTH]: '1 mois offert !',
  },
  customer_service: {
    online_chat: 'Chat en ligne',
    phone: 'Téléphone',
  },
  application_fee_zero: 'Aucun',
  application_fee_other: '{{count}}€ (inclus dans le prix affiché)',
  coverage_levels: {
    consultation: 'Soins courants',
    hospitalization: 'Hospitalisation',
    optical: 'Optique',
    dental: 'Dentaire',
  },
  alternativeMedicine: 'Médecine douce',
  responsibleContract: 'Contrat responsable',
  administrationFee: 'Frais de dossier',
  customerService: 'Service client',
}
