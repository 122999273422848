import {
  matchPath,
} from 'react-router-dom'

import {
  useFetchUserQuery,
} from '@root/services/user'

import useGetOffersByEstimationId from './useGetOffersByEstimationId'

const useGetUserFromEstimationIdRouteParam = () => {
  const match = matchPath({
    path: '/offre/:estimationId',
  }, window.location.pathname)
  const estimationId = match?.params?.estimationId ?? ''

  const {
    data,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId, {
    skip: !estimationId,
  })

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  return fetchUser
}

export default useGetUserFromEstimationIdRouteParam
