import {
  Combobox,
} from '@selectra-it/selectra-ui'
import {
  forwardRef, useState,
} from 'react'
import {
  LiteralUnion, RegisterOptions,
} from 'react-hook-form'

import {
  formatDate,
} from '@root/util/date'
import useGetInputStatus from '@hooks/useGetInputStatus'

export interface CallbackDate {
  label: string,
  id: string,
}

interface CallbackRequestDateProps {
  value: CallbackDate | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void,
  name: string,
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
}

const CallbackRequestDate = forwardRef<HTMLInputElement, CallbackRequestDateProps>(
  ({
    onChange, value, name, error,
  }, ref) => {
    const [query, setQuery] = useState<string>('')
    const status = useGetInputStatus(error)
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const afterTomorrow = new Date(tomorrow)
    afterTomorrow.setDate(tomorrow.getDate() + 1)

    const options: (CallbackDate)[] = [
      {
        label: 'Le plus vite possible',
        id: 'now',
      },
      {
        label: formatDate(tomorrow),
        id: formatDate(tomorrow),
      },
      {
        label: formatDate(afterTomorrow),
        id: formatDate(afterTomorrow),
      }
    ]

    const displayValue = (item: CallbackDate | null): string => item?.label ?? ''

    const filteredItems =
      query === ''
        ? options
        : (options.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase())
        ))

    return (
      <Combobox<CallbackDate>
        id='callback-date'
        ref={ref}
        displayValue={displayValue}
        item={value}
        setQuery={setQuery}
        setItem={onChange}
        items={filteredItems}
        label={'Quand'}
        status={status}
        name={name}
        canClearValue={false}
        immediate={true}
      />
    )
  })

CallbackRequestDate.displayName = 'CallbackRequestDate'

export default CallbackRequestDate
