import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  FC, MouseEventHandler,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'
import {
  useParams,
} from 'react-router-dom'

import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@root/services/formSlice'
import Profession from '@components/form/Profession'

interface ProfessionInformationProps {
  backHandler: MouseEventHandler<HTMLButtonElement>
  nextHandler: () => void
}

const ProfessionInformation: FC<ProfessionInformationProps> = ({
  nextHandler, backHandler,
}) => {
  const dispatch = useDispatch()
  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const {
    form: {
      control,
      handleSubmit,
      formState: {
        errors,
      },
    },
  } = useEstimationWizardForm({})

  const params = useParams()

  const id = parseInt(params.id as string, 10)

  const errorType = errors.adults?.[id]?.profession?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectProfession'),
  }, errorType)

  const onSubmit: SubmitHandler<WizardFormData> = data => {
    dispatch(setFormField(data))
    nextHandler()
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col gap-6'>

        <InputGroup
          errors={inputErrors}
        >
          <Profession
            control={control}
            error={errorType}
            label={t('wizard:wizard.steps.profession.label')}
            id={id}
          />
        </InputGroup>
      </div>
      <NavigationButtons onBackHandler={backHandler}/>

    </form>
  )
}

export default ProfessionInformation
