export default {
  wizard: {
    steps: {

      insuranceGender: {
        items: {
          men: 'Un homme',
          women: 'Une femme',
        },
      },
      birthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/1980',
      },
      profession: {
        label: 'Profession',
      },
      socialRegime: {
        label: 'Régime social',
        description: '88% des Français sont couverts par le régime général de la Sécurité Sociale.',
      },
      children: {
        label: 'Nombre d\'enfants',
      },
      childrenBirthDate: {
        label: 'Date de naissance',
        description: 'Ex : 31/01/2024',
      },
      postalCode: {
        label: 'Code postal',
        description: 'Ex : 75000 Paris',
      },
      insuranceDate: {
        label: 'Date de début du contrat',
        description: 'Ex : 31/01/2024',
      },
      name: {
        firstName: {
          label: 'Prénom',
        },
        lastName: {
          label: 'Nom',
        },
      },
      email: {
        label: 'Adresse e-mail',
        nextButton: 'Voir les offres',
      },
      regularHealthLevel: {
        description: 'Soins courants : visites chez le généraliste, radiographies, médicaments et analyses.',
      },
      hospitalLevel: {
        description: 'Hospitalisation : frais de séjour, actes des médecins et chambres particulières en hôpital.',
      },
      opticalLevel: {
        description: 'Optique : une monture avec verres complexes et lentilles.',
      },
      dentalLevel: {
        description: 'Dentaire : l’orthodontie, des soins et prothèses dentaires.',
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        description: 'Ex : 06 86 52 04 12',
      },
      next: 'Suivant',
    },
  },
}
