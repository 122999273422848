import {
  useEffect,
} from 'react'

import {
  MerStatus, useFetchMerQuery,
} from '@root/services/offers'

const useMerQueryPolling = ({
  estimationId,
  offerId,
  merId,
}: {
  estimationId: string,
  offerId: string,
  merId: string
}) => {
  const query = useFetchMerQuery({
    estimationId, offerId, merId,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (query.data?.status === MerStatus.DONE || query.data?.status === MerStatus.ERROR) {
        clearInterval(interval)
      } else {
        query.refetch()
      }
    }, 1200)

    return () => {
      clearInterval(interval)
    }
  }, [query])

  return query
}

export default useMerQueryPolling
