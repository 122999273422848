import {
  useTransition, animated,
} from '@react-spring/web'
import {
  FC,
} from 'react'
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import {
  InteractionMessage,
} from '@selectra-it/selectra-ui'

import {
  routes,
} from '@root/routes/wizad'
import {
  wizardRoutes,
} from '@root/routes/wizard-routes'
import {
  AdultInformation, ChildrenBirthDate,
} from '@components/wizard/steps'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  getFormState,
} from '@root/services/formSlice'

import messages from './messages'

const Wizard: FC = () => {
  const location = useLocation()

  const transitions = useTransition(location, {
    from: {
      transform: 'translateX(10%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  })

  const pathname = `/${location.pathname.split('/').at(-1)}` as wizardRoutes
  const state = useAppSelector(state => getFormState(state))

  const interactionMessages = messages[pathname]?.(state, location.pathname)

  return (
    <div className="container mx-auto flex grow flex-col flex-wrap items-center px-5 md:px-0">
      <div className='flex w-full grow'>
        {transitions((styles, location) => (
          <animated.div style={styles} className='mb-6 flex w-full grow flex-col justify-between space-y-6 md:mb-24'>
            <div className="flex w-full gap-2 md:gap-4">
              {interactionMessages && <InteractionMessage messages={interactionMessages} />}
            </div>

            <div className={'container m-auto flex w-full grow'} >
              <Routes location={location}>
                <Route path='/information-beneficiaire/:id/*' element={<AdultInformation />} />
                <Route path='/nombre-enfants/:id/age-enfant' element={<ChildrenBirthDate />} />
                {routes.map(route => (
                  <Route key={route.path} element={<route.element />}
                    path={route.path}
                  />
                )) }
              </Routes>
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  )
}

export default Wizard
