import {
  FC, useState,
} from 'react'
import {
  v4 as uuidv4,
} from 'uuid'
import {
  InputGroup, TextInput, getErrorMessages, ErrorMessages, CheckboxClassic, ButtonType, ButtonSize, Button,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
import {
  SubmitHandler,
} from 'react-hook-form'

import EmailValidator from '@root/util/validation/EmailValidator'
import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  ReactComponent as Arrow,
} from '@assets/icons/arrow-backward.svg'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  useCreateEstimationMutation,
} from '@root/services/offers'
import {
  useCreateUserMutation,
} from '@root/services/user'

const Email: FC = () => {
  const navigate = useNavigate()
  const {
    form: {
      register,
      handleSubmit,
      formState: {
        errors,
      },
    }, onBackHandler,
  } = useEstimationWizardForm({ })
  const [loading, setIsLoading] = useState(false)
  const [createEstimation] = useCreateEstimationMutation()
  const [createUser] = useCreateUserMutation()

  const inputStatus = useGetInputStatus(errors.email?.type)

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addEmail'),
    email: 'Adresse e-mail invalide',
  }, errors.email?.type)

  const onSubmit: SubmitHandler<WizardFormData> = async data => {
    const userId = uuidv4()
    const estimationId = uuidv4()

    setIsLoading(true)

    try {
      await createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phoneNumber.number, // TODO
        country_code: 'FR', // TODO
        uuid: userId,
        birthDate: data.adults[0].birthDate,
      }).unwrap()

      const adults = data.adults.map(adult => ({
        birthDate: adult.birthDate,
        gender: adult.gender,
        professionId: adult.profession?.id,
        socialRegimeId: adult.socialRegime?.id,
        type: adult.type,
      }))

      await createEstimation({
        consultation: data.regularHealthLevel,
        hospitalization: data.hospitalLevel,
        optic: data.opticalLevel,
        dental: data.dentalLevel,
        address: data.address,
        contractStartDate: data.insuranceDate,
        userId,
        uuid: estimationId,
        adults,
        children: data.children,
      }).unwrap()

      navigate(`/offre/${estimationId}?preload=true`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='flex flex-col'>
      <form
        className='flex w-full grow flex-col justify-between space-y-6'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex flex-col gap-6'>
          <InputGroup errors={inputErrors}>
            <TextInput
              label={t('wizard:wizard.steps.email.label')}
              {...register('email', {
                required: true,
                validate: {
                  email: EmailValidator,
                },
              })}
              status={inputStatus}
              dataCy='email'
            />
          </InputGroup>

          <CheckboxClassic
            {...register('terms_and_conditions', {
              required: true,
            })}
            id="terms_and_conditions"
            label="*J’accepte que mes données soient utilisées afin que les partenaires de Selectra me contactent si je demande à être mis en relation."
            error={errors.terms_and_conditions}
          />
        </div>
        <div className='flex gap-5'>
          <Button
            type='button'
            variant={ButtonType.LIGHT}
            size={ButtonSize.LARGE}
            pill
            rounded
            iconLeft={Arrow}
            onClick={onBackHandler}
          />
          <Button
            type='submit'
            disabled={loading}
            label={t('wizard:wizard.steps.email.nextButton')}
            variant={ButtonType.SUCCESS}
            size={ButtonSize.LARGE}
            pill
            dataCy={'submit'}
          />
        </div>
      </form>

      <p className='mt-16 text-xs text-neutral-400'>
          ** La société Selectra SAS traite les données personnelles recueillies dans ce questionnaire pour proposer des offres correspondant au profil de l’internaute, réaliser des opérations d’analyse et de statistiques, pour assurer la relation commerciale avec les clients. Vous disposez de différents droits (notamment le droit d’opposition, d’accès, de rectification, d’effacement). Pour en savoir plus, consultez notre {' '}
        <a className='text-primary-400 underline' href="https://selectra.info/la-page-a-jojo-le-dpo"
          target="_blank" rel="noopener noreferrer"
        >Charte de protection des données personnelles.</a>
      </p>
    </div>
  )
}

export default Email
