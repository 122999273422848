import {
  Outlet,
  createBrowserRouter,
} from 'react-router-dom'

import CallbackCreate from '@components/ui/SubscriptionModal/CallbackCreate'
import App from '@root/App'
import {
  OffersPage, Wizard,
} from '@root/pages'
import Mer from '@components/ui/SubscriptionModal/Mer'
import SubscriptionStatus from '@components/ui/SubscriptionModal/SubscriptionStatus'
import CallbackStatus from '@components/ui/SubscriptionModal/CallbackStatus'

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'offre/:estimationId/*?',
        element: <OffersPage />,
        children: [
          {
            path: ':offerId/mer',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Mer />,
              },
              {
                path: 'subscription/:merId',
                element: <SubscriptionStatus />,
              },
              {
                path: 'callback',
                element: <CallbackCreate />,
              },
              {
                path: 'callback/:merId',
                element: <CallbackStatus />,
              }
            ],
          }
        ],
      },
      {
        path: '*',
        element: <Wizard />,
      }
    ],
  }
])
