import {
  useTranslation,
} from 'react-i18next'

const Loading = () => {
  const {
    t,
  } = useTranslation(['modal'])

  return (
    <div className='mx-auto text-center text-neutral-400 md:p-2 md:text-lg'>
      {t('modal:modal.callbackWaiting.description')}
    </div>
  )
}

export default Loading
