import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  ReactComponent as Approval,
} from '@assets/icons/approval.svg'
import {
  Offer,
} from '@root/domain/Offer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'

import DocumentList from '../Documents/DocumentList'
import CustomerServiceIcon from '../CustomerServiceIcon'
import AdministrationFeesIcon from '../AdministrationFeesIcon'

interface ExpandedOfferProps {
  isExpanded: boolean,
  offer: Offer
}

const ExpandedOffer: FC<ExpandedOfferProps> = ({
  isExpanded,
  offer,
}) => {
  const insurer = useAppSelector(state => selectInsurerById(state, offer.insurerId))

  const {
    t,
  } = useTranslation(['offers', 'common'])

  if (!insurer) {
    return (
      <></>
    )
  }

  const {
    description,
    administrationFee,
    customerService,
  } = insurer

  return (
    <div className={`grid overflow-hidden transition-grid-template-rows duration-1000 ${isExpanded ? 'mb-2 grid-rows-layout-1' : 'grid-rows-layout-0'}`}>
      <div className={`min-h-0 self-start transition-visibility duration-1000 ${isExpanded ? 'visible' : 'invisible'}`}>

        <h3 className='gap-2 font-semibold text-neutral-500 md:text-lg'>A propos</h3>

        <ul className='list-inside list-disc text-left font-helvetica text-sm text-neutral-400 '>
          {description.split(' -').map(item => item.replace('-', '').trim()).map(description => {
            return (
              <li key={description} className="mt-2 whitespace-normal text-sm font-normal text-neutral-400 before:-ml-2 before:content-[''] md:text-base">
                {description}
              </li>
            )
          })}
        </ul>
        <div className='mt-4 grid grid-cols-2 items-center'>
          <div className='flex flex-col gap-2 '>
            <h3 className='font-semibold text-neutral-500 md:text-lg'>{t('offers:administrationFee')}</h3>
            <p className='flex items-center gap-1 text-sm text-neutral-400 md:text-base'><AdministrationFeesIcon fee={administrationFee} /> {t('offers:application_fee', {
              count: administrationFee,
            })}</p>
          </div>
          <div className='flex flex-col gap-2 '>
            <h3 className='font-semibold text-neutral-500 md:text-lg'>{t('offers:customerService')}</h3>
            <p className='flex items-center gap-1 text-sm text-neutral-400 md:text-base'><CustomerServiceIcon type={customerService} /> {t(`offers:customer_service.${customerService}` as 'customer_service.phone')}</p>
          </div>
        </div>
        <div className='mt-6 flex flex-col gap-2'>
          <h3 className='font-semibold text-neutral-500 md:text-lg'>{t('offers:responsibleContract')}</h3>
          <p className='flex items-center gap-1 text-center text-sm text-neutral-400 md:text-base'><Approval/>{offer.responsibleContract ? t('common:yes') : t('common:no')}</p>
        </div>

        <DocumentList documents={offer.documents} />
      </div>
    </div>
  )
}

export default ExpandedOffer
