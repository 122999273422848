import {
  Button,
  ButtonSize,
  ButtonType,
  Modal,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useState,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import Icon from '@components/Icon'
import HelpRequestCallback from '@components/ui/HelpModal/HelpRequestCallback'
import {
  LeadSource, Owner,
} from '@root/domain/crm/Record'
import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'

interface CallbackBannerProps {
  title: string,
  leadSource: LeadSource,
}

const CallbackBanner: FC<CallbackBannerProps> = ({
  title,
  leadSource,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const {
    data,
  } = useGetUserFromEstimationIdRouteParam()
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data: estimationData,
  } = useGetOffersByEstimationIdQuery({
    estimationId,
  })

  const mainRecipient = estimationData?.estimation.adults.find(adult => adult.type === 'PRINCIPAL')

  return (
    <div className="flex flex-col items-start gap-8 bg-gradient-radial from-[#1460AA] to-[#002D77] p-4 md:rounded-2xl md:px-12 md:py-8">
      <div className="flex flex-col items-start gap-2 text-white">
        <h3 className="text-base font-bold leading-tight md:text-[24px]">{title}</h3>
        <p className="text-left text-sm md:text-base">Nos conseillers comparent les contrats au téléphone et répondent à vos questions gratuitement.</p>
      </div>

      <div className="flex flex-col items-center gap-4 self-stretch md:flex-row">
        <a className="flex h-8 w-full items-center justify-center gap-2 rounded-full bg-white px-3 py-2 outline-none  ring-offset-2 hover:bg-primary-100 focus-visible:ring-2  focus-visible:ring-white hover:focus-visible:ring-primary-100 disabled:bg-neutral-100 disabled:outline-none" href='tel:+33 01 82 88 31 79'>
          <Icon name="phone" size={16}
            className='inline-block fill-primary-450'
          />
          <span className='text-base font-semibold leading-tight text-primary-450 md:text-[15px] lg:text-[17px]'>01 82 88 31 79</span>
        </a>
        <span className="hidden items-center text-center text-[19px] font-semibold leading-tight text-white md:flex">OU</span>
        <Button
          type='button'
          variant={ButtonType.LIGHT}
          size={ButtonSize.SMALL}
          pill
          label='Rappel gratuit'
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      <Modal open={isModalOpen} setOpen={setIsModalOpen}>
        <HelpRequestCallback user={data}
          owner={Owner.INSURANCE_FRANCE_MANAGER}
          leadSource={leadSource}
          address={estimationData?.estimation.address}
          mainRecipient={mainRecipient}
        />
      </Modal>
    </div>
  )
}

export default CallbackBanner
