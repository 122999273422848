export interface PhoneNumberDetails {
  countryCode: number
  nationalNumber: string
  numberOfLeadingZeros: number
  countryCodeSource: number
}

export interface PhoneNumber {
  countryCode: string
  number: PhoneNumberDetails
}

export enum CustomerService {
  online_chat = 'online_chat',
  phone = 'phone',
}

export interface Insurer {
  id: string
  name: string
  description: string
  administrationFee: number
  customerService: CustomerService
  leadSource?: string
  phoneNumber?: PhoneNumber
  healthOnlineSubscriptionEnabled: boolean
  homeOnlineSubscriptionEnabled: boolean
  oriasNumber?: string
  sirenNumber?: string
  slug: string
  redistributionRate?: number
  managementFees?: number
  activeHealthInsurer: boolean
  activeHomeInsurer: boolean
  address?: string
  postalCode?: string
  locality?: string
  isHealthInsurer: boolean
  isHomeInsurer: boolean
}
