import {
  FC, useEffect,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  MerStatus,
  useFetchMerQuery,
} from '@root/services/offers'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  Insurer,
} from '@root/domain/Insurer'
import {
  Offer,
} from '@root/domain/Offer'

import SubscriptionComplete from './Status/SubscriptionComplete'
import Loading from './Status/Loading'

const SubscriptionStatus: FC = () => {
  const {
    estimationId, offerId, merId,
  } = useParams<{estimationId: string, offerId: string, merId: string}>()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state)) as Offer
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? '')) as Insurer

  const {
    data,
    refetch,
    isSuccess,
  } = useFetchMerQuery({
    estimationId: estimationId as string,
    offerId: offerId as string,
    merId: merId as string,
  })

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (data?.status !== MerStatus.DONE && data?.status !== MerStatus.ERROR) {
      interval = setInterval(() => {
        refetch()
      }, 1200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [refetch, data?.status])

  if (!offer || !insurer) {
    return null
  }

  return (
    <>
      {isSuccess && data.status === MerStatus.DONE && <SubscriptionComplete url={data.url} insurer={insurer} />}
      {isSuccess && data.status !== MerStatus.DONE && <Loading /> }
    </>

  )
}

export default SubscriptionStatus
