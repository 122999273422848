export enum wizardRoutes {
  PRESENTATION = '/',
  BENEFICIARY = '/beneficiaires',
  ADULT_INFORMATION = '/information-beneficiaire',
  INSURANCE_GENDER = '/sexe',
  BIRTH_DATE = '/age',
  PROFESSION = '/profession',
  SOCIAL_REGIME = '/regime-social',
  CHILDREN = '/nombre-enfants',
  CHILDREN_BIRTH_DATE= '/age-enfant',
  POSTAL_CODE = '/code-postal',
  INSURANCE_DATE = '/date-debut-contrat',
  REGULAR_HEALTH_LEVEL = '/besoin-soins-courants',
  HOSPITAL_LEVEL = '/besoin-hospitalisation',
  OPTICAL_LEVEL = '/besoin-optique',
  DENTAL_LEVEL = '/besoin-dentaire',
  NAME = '/nom',
  PHONE_NUMBER = '/numero-de-telephone',
  EMAIL = '/mail',
}
