import {
  v4 as uuidv4,
} from 'uuid'
import {
  Button, ButtonType, ButtonSize,
} from '@selectra-it/selectra-ui'
import {
  t,
} from 'i18next'
import {
  FC,
} from 'react'
import {
  Link, useNavigate, useParams,
} from 'react-router-dom'

import {
  ReactComponent as Verified,
} from '@assets/icons/verified.svg'
import {
  ReactComponent as ArrowRight,
} from '@assets/icons/arrow-right.svg'
import {
  ReactComponent as Mobile,
} from '@assets/icons/mobile.svg'
import {
  useCreateOfferMerMutation,
} from '@root/services/offers'
import {
  MerType,
} from '@root/domain/Offer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'

const Subscription: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()
  const navigate = useNavigate()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  const [createMer] = useCreateOfferMerMutation()

  if (!offer || !insurer) {
    return null
  }

  const handleOnlineSubscription = async () => {
    const merId = uuidv4()
    if (!offer.url) {
      await createMer({
        id: merId,
        type: MerType.SUBSCRIPTION_REQUEST,
        offerId: offer.id,
        estimationId: offer.estimationId,
      })
    }

    navigate(`/offre/${offer.estimationId}/${offer.id}/mer/subscription/${merId}`)
  }

  return (
    <div className="flex flex-col gap-4  md:p-2">
      <h3 className='flex gap-2 font-semibold md:mb-2 md:text-lg'><Verified className='shrink-0'/> {t('modal:modal.subscription.title')}{offer.title}</h3>
      {insurer?.healthOnlineSubscriptionEnabled && (
        <Button
          label={t('modal:modal.subscription.primaryButton')}
          type='submit'
          variant={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM}
          iconRight={ArrowRight}
          pill
          onClick={handleOnlineSubscription}
        />
      )}
      <Link to={`/offre/${offer.estimationId}/${offer.id}/mer/callback/`}>
        <Button
          type='submit'
          label={t('modal:modal.subscription.secondaryButton')}
          variant={ButtonType.LIGHT}
          size={ButtonSize.MEDIUM}
          iconLeft={Mobile}
          pill
        />
      </Link>
    </div>
  )
}

export default Subscription
