import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC,
  FunctionComponent,
  SVGProps, useState,
} from 'react'

import Apicil from '@assets/insurers/apicil.png'
import {
  ReactComponent as ArrowExpandLess,
} from '@assets/icons/arrow-expand-less.svg'
import {
  Offer,
} from '@root/domain/Offer'
import {
  ReactComponent as Alptis,
} from '@assets/insurers/alptis.svg'
import {
  ReactComponent as Heyme,
} from '@assets/insurers/heyme.svg'
import {
  ReactComponent as April,
} from '@assets/insurers/april.svg'
import {
  ReactComponent as Miltis,
} from '@assets/insurers/miltis.svg'
import {
  ReactComponent as Meilleurtaux,
} from '@assets/insurers/meilleurtaux.svg'
import {
  ReactComponent as ArrowExpandMore,
} from '@assets/icons/arrow-expand-more.svg'
import {
  ReactComponent as Puzzle,
} from '@assets/icons/puzzle.svg'
import Flag from '@components/ui/Flag'
import {
  tagColors,
  useGetEstimationTags,
} from '@hooks/offers/useGetEstimationTags'
import TagContainer from '@components/ui/TagContainer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  FrenchNumberFormater,
} from '@root/plugins/number'

import Level from './Level'
import ExpandedOffer from './ExpandedOffer'
import SubscriptionButton from './Subscription/SubscriptionButton'
import AlternativeMedicine from './AlternativeMedicine'
import SirenOriasNumber from './SirenOriasNumber'

interface OfferItemProps {
  offer: Offer,
}

type Insurers = {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>> | string
};

const insurers: Insurers = {
  '6578d964-6d33-4abf-875b-d1be65e0fbc1': Alptis,
  'd358ed16-9419-4801-836d-61772558d2e1': Heyme,
  '9218f071-739e-4741-80c7-786580388228': Miltis,
  'ca6df745-8b0e-4b1e-be76-13a158774392': April,
  '8218abc6-3a22-4d03-98a5-a7d757b4d902': Meilleurtaux,
  'f63e4960-523d-45d2-b321-d62f4440b700': Apicil,
}

const OfferItem: FC<OfferItemProps> = ({
  offer,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    matchRate, monthlyPrice, title, insurerId, coverage,
  } = offer

  const {
    tag,
  } = useGetEstimationTags(offer.estimationId, offer.id)
  const insurer = useAppSelector(state => selectInsurerById(state, offer.insurerId))

  const parsedPrice = FrenchNumberFormater.format(monthlyPrice / 100)

  const handleOnMoreClick = () => {
    setIsExpanded(!isExpanded)
  }

  const borderColor = tag ? tagColors[tag].border : ''

  const Logo = insurers[insurerId]

  if (!Logo || !insurer) {
    return null
  }

  return (
    <div className="flex w-full flex-col items-start p-0 last:mb-6 md:last:mb-24">
      { tag && <TagContainer tag={tag} />}
      <div className={`flex w-full flex-col whitespace-nowrap bg-white p-4 pb-6 md:rounded-2xl md:p-6 ${tag ? `md:border-2 ${borderColor}` : ''}`} >
        <div className='mb-6 md:hidden'>
          {tag && (
            <Flag
              tag={tag}
            />)}
        </div>
        <div className=' flex w-full flex-row items-center justify-between'>
          <div className='flex flex-col items-start justify-start gap-1'>
            {typeof Logo === 'string'
              ? <img src={Logo} className='h-6'/>
              : <Logo height={24} /> }
            <SirenOriasNumber insurer={insurer}/>
            <p className='mt-1 font-semibold text-neutral-500 md:text-lg'>{title}</p>
          </div>

          <div className='flex text-success-400'>
            <span className='text-3xl font-bold md:text-[40px]'>{parsedPrice}</span>
            <p className='flex flex-col '>
              <span className='text-sm md:text-lg'>€</span>
              <span className='text-xs md:text-base'>/mois</span>
            </p>
          </div>

        </div>

        <p className='mt-2 flex items-center gap-1 text-sm text-neutral-350 md:text-base'> <Puzzle/> Répond à <strong>{matchRate} %</strong> à vos besoins</p>

        <h3 className='mb-2 mt-6 font-semibold text-neutral-500 md:text-lg'>Niveau de remboursement</h3>
        <ul className='mb-6 flex flex-col gap-2'>
          {Object.entries(coverage).map(([key, level]) => {
            return (
              <Level
                key={key}
                level={level}
                label={key}
                isIncluded={false}
              />
            )
          })}
          <AlternativeMedicine alternativeMedicine={offer.alternativeMedicine} />
        </ul>

        <ExpandedOffer isExpanded={isExpanded} offer={offer} />

        <div className='flex gap-4 first:whitespace-nowrap'>
          <div className='shrink grow'>
            <Button
              label={isExpanded ? 'Fermer' : 'En savoir plus'}
              type='submit'
              variant={ButtonType.LIGHT}
              size={ButtonSize.MEDIUM}
              iconRight={isExpanded ? ArrowExpandLess : ArrowExpandMore}
              pill
              onClick={handleOnMoreClick}
              hasBorderColor={true}
            />
          </div>
          <div className='shrink grow'>
            <SubscriptionButton offer={offer} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferItem
