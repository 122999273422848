import {
  FC,
  useRef,
} from 'react'
import {
  ButtonGroup, InputGroup, getErrorMessages, ErrorMessages, ButtonGroupVariant,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import {
  useGetLevelQuery,
} from '@services/level'
import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import useGetButtonGroupStatus from '@hooks/useGetButtonGroupStatus'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'

const HospitalLevel: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    data: options,
  } = useGetLevelQuery()
  const {
    t,
  } = useTranslation(['wizard'])
  const {
    form: {
      register, watch,
      formState: {
        errors,
        isDirty,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({})

  const errorType = errors.hospitalLevel?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errorType)
  const status = useGetButtonGroupStatus(errorType)

  useSubmitFormOnChange(isDirty, watch('hospitalLevel'), formRef)

  if (!options) return null

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
      ref={formRef}
    >
      <InputGroup
        description={t('wizard:wizard.steps.hospitalLevel.description')}
        errors={inputErrors}
      >
        <div className='flex flex-col gap-6'>

          <ButtonGroup options={options}
            status={status}
            variant={ButtonGroupVariant.DARK}
            {...register('hospitalLevel', {
              required: true,
            })}
            currentValue={watch('hospitalLevel')}
          />

        </div>

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>

    </form>
  )
}

export default HospitalLevel
