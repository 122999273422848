import {
  FC, useState,
} from 'react'
import {
  Link,
  useLocation,
} from 'react-router-dom'

import Stepper from '@components/wizard/Stepper'
import {
  ReactComponent as SelectraLogoMobile,
} from '@assets/selectra-logo-mobile.svg'
import {
  ReactComponent as SelectraLogoDesktop,
} from '@assets/selectra-logo-desktop.svg'

import HelpModal from './HelpModal'

const Header: FC = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const {
    pathname,
  } = useLocation()

  const isOfferPage = pathname.includes('/offre')
  const handleModal = () => setIsHelpModalOpen(true)

  return (
    <header className='flex h-12 items-center justify-between bg-white px-5 py-3' >
      <div className='container flex items-center justify-between'>

        {!isOfferPage
          ? <Stepper />

          : <Link to="/">
            <SelectraLogoMobile className="block md:hidden" />
            <SelectraLogoDesktop className="hidden md:block" />
          </Link>
        }

        <HelpModal isHelpModalOpen={isHelpModalOpen} setIsHelpModalOpen={setIsHelpModalOpen}/>

        <p className='cursor-pointer text-sm font-bold text-primary-400 underline' onClick={handleModal}>Aide</p>
      </div>
    </header>
  )
}

export default Header
