import {
  InsurancePerson,
  Children,
  PostalCode,
  InsuranceDate,
  RegularHealthLevel,
  HospitalLevel,
  OpticalHealthLevel,
  DentalLevel,
  Name,
  Email,
  Presentation,
  PhoneNumber,
} from '@components/wizard/steps'
import {
  Step,
} from '@root/domain/Wizard'

import {
  wizardRoutes,
} from './wizard-routes'

export enum StepperGroup {
  YOUR_SITUATION = 'YOUR_SITUATION',
  OTHER_BENEFICIARY = 'OTHER_BENEFICIARY',
  YOUR_INFORMATION = 'YOUR_INFORMATION',
}

export const steps: Step[] = [
  {
    name: 'presentation',
    path: wizardRoutes.PRESENTATION,
    shouldRender: () => true,
    element: () => <Presentation />,
    group: StepperGroup.YOUR_SITUATION,
  },
  {
    name: 'insurancePerson',
    path: wizardRoutes.BENEFICIARY,
    shouldRender: () => true,
    element: () => <InsurancePerson />,
    group: StepperGroup.YOUR_SITUATION,
  },
  {
    name: 'children',
    path: wizardRoutes.CHILDREN,
    shouldRender: () => true,
    element: () => <Children />,
    group: StepperGroup.OTHER_BENEFICIARY,
  },
  {
    name: 'PostalCode',
    path: wizardRoutes.POSTAL_CODE,
    shouldRender: () => true,
    element: () => <PostalCode />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'InsuranceDate',
    path: wizardRoutes.INSURANCE_DATE,
    shouldRender: () => true,
    element: () => <InsuranceDate />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'regularHealthLevel',
    path: wizardRoutes.REGULAR_HEALTH_LEVEL,
    shouldRender: () => true,
    element: () => <RegularHealthLevel />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'hospitalLevel',
    path: wizardRoutes.HOSPITAL_LEVEL,
    shouldRender: () => true,
    element: () => <HospitalLevel />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'opticalLevel',
    path: wizardRoutes.OPTICAL_LEVEL,
    shouldRender: () => true,
    element: () => <OpticalHealthLevel />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'dentalLevel',
    path: wizardRoutes.DENTAL_LEVEL,
    shouldRender: () => true,
    element: () => <DentalLevel />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'name',
    path: wizardRoutes.NAME,
    shouldRender: () => true,
    element: () => <Name />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'phoneNumber',
    path: wizardRoutes.PHONE_NUMBER,
    shouldRender: () => true,
    element: () => <PhoneNumber />,
    group: StepperGroup.YOUR_INFORMATION,
  },
  {
    name: 'email',
    path: wizardRoutes.EMAIL,
    shouldRender: () => true,
    element: () => <Email />,
    group: StepperGroup.YOUR_INFORMATION,
  }
]

export const routes = [
  ...steps.map(({
    path, element,
  }) => ({
    path,
    element,
  }))
]
