import {
  InputGroup, getErrorMessages, ErrorMessages, Datepicker,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  FC,
} from 'react'

import useEstimationWizardForm from '@hooks/useWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'

const InsuranceDate: FC = () => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({ })

  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const status = useGetInputStatus(errors?.insuranceDate?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addInsuranceDate'),
  }, errors?.insuranceDate?.type)

  const now = new Date()
  const inOneMonth = new Date().setMonth(now.getMonth() + 1)

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={onSubmitHandler()}
    >
      <InputGroup
        errors={inputErrors}
        description={t('wizard:wizard.steps.insuranceDate.description')}
      >
        <Datepicker
          label={t('wizard:wizard.steps.insuranceDate.label')}
          maxDate={inOneMonth}
          status={status}
          minDate={new Date()}
          {...register('insuranceDate', {
            required: true,
          })}
          dataCy="insurance-date"
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler}/>
    </form>
  )
}

export default InsuranceDate
